import { NgModule } from "@angular/core";
import { S25TriggerRerenderDirective } from "./s25.trigger.rerender.directive";

@NgModule({
    imports: [],
    exports: [S25TriggerRerenderDirective],
    providers: [S25TriggerRerenderDirective],
    declarations: [S25TriggerRerenderDirective],
})
export class S25TriggerRerenderModule {}
