//@author: devin
import { Directive, Input, OnDestroy, OnInit } from "@angular/core";
import { S25Util } from "../../util/s25-util";
import { jSith } from "../../util/jquery-replacement";

@Directive({
    selector: "[s25-typeahead]",
})
export class S25TypeaheadDirective implements OnInit, OnDestroy {
    static DEFAULT_DELAY_MS: number = 250;
    static MIN_FILTER_LEN: number = 2;

    @Input() search: string = "";
    @Input() items: any[];
    @Input() delayMs?: number;
    @Input() prop?: string;
    @Input() filter?: (search: string) => Promise<any[]>;
    @Input() action?: () => any;

    prevSearch: string = "";
    running: boolean = true;
    counter: number = 1;
    origItems: any[] = [];

    constructor() {}

    shouldRunSearch(prevSearch: string, search: string) {
        return prevSearch !== search;
    }

    typeaheadAction() {
        if (this.running) {
            if (this.action) {
                this.action();
            } else {
                let runSearch = this.shouldRunSearch(this.prevSearch, this.search);
                if (runSearch) {
                    this.prevSearch = this.search;
                    this.counter++;
                    let counterInstance = this.counter;
                    this.search = S25Util.toStr(this.search);

                    if (!this.search || (this.filter && this.search.length < S25TypeaheadDirective.MIN_FILTER_LEN)) {
                        Array.prototype.splice.apply(this.items, [0]);
                        jSith.forEach(this.origItems, (i: number, item: any) => {
                            this.items.push(item);
                        });
                    } else if (this.filter) {
                        this.filter(this.search).then((items: any[]) => {
                            if (this.counter === counterInstance) {
                                Array.prototype.splice.apply(this.items, [0]);
                                jSith.forEach(items, (i: number, item: any) => {
                                    this.items.push(item);
                                });
                            }
                        });
                    } else if (this.prop) {
                        let regex = new RegExp(S25Util.escapeRegExp(this.search), "i");
                        Array.prototype.splice.apply(this.items, [0]);
                        jSith.forEach(this.origItems, (i: number, item: any) => {
                            if (item && item[this.prop] && regex.test(this.search)) {
                                this.items.push(item);
                            }
                        });
                    }
                }
            }

            setTimeout(() => {
                this.typeaheadAction();
            }, this.delayMs);
        }
    }

    ngOnInit() {
        this.delayMs = this.delayMs || S25TypeaheadDirective.DEFAULT_DELAY_MS;
        this.items = this.items || [];
        this.origItems = [].concat(this.items);
        this.typeaheadAction();
    }

    ngOnDestroy() {
        this.running = false;
    }
}
