import { Api } from "../../api/api";
import { S25TimepickerComponent } from "./s25.timepicker.component";
import { S25Util } from "../../util/s25-util";

export class TimepickerApi extends Api {
    public static init(relativeElem: HTMLElement) {
        Api.callApiFn(relativeElem, "s25-timepicker", "initFn");
    }

    public static refresh(relativeElem: HTMLElement, newDate: any) {
        return Api.callApiFn(relativeElem, "s25-timepicker", null, null, (comp: S25TimepickerComponent) => {
            if (newDate && S25Util.date.isValid(newDate)) {
                comp.updateTime(newDate);
            }
        });
    }
}
