//@author: nichole

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { S25ToggleButtonComponent } from "./s25.toggle.button.component";

@NgModule({
    declarations: [S25ToggleButtonComponent],
    imports: [CommonModule, FormsModule],
    providers: [S25ToggleButtonComponent],
    exports: [S25ToggleButtonComponent],
})
export class S25ToggleButtonModule {}
