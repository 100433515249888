//@author: devin

import { NgModule } from "@angular/core";
import { S25TypeaheadDirective } from "./s25.typeahead.directive";

@NgModule({
    declarations: [S25TypeaheadDirective],
    imports: [],
    exports: [S25TypeaheadDirective],
    providers: [S25TypeaheadDirective],
})
export class S25TypeaheadModule {}
