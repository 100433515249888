import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";

@Directive({
    selector: "[s25-ng-trigger-rerender]",
})
export class S25TriggerRerenderDirective {
    constructor(
        private templateRef: TemplateRef<unknown>,
        private viewContainer: ViewContainerRef,
    ) {}

    @Input() set "s25-ng-trigger-rerender"(_: unknown) {
        this.viewContainer.clear();
        this.viewContainer.createEmbeddedView(this.templateRef);
    }
}
