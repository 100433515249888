//@author: mandy

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { S25TimepickerComponent } from "./s25.timepicker.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { PopoverModule } from "../s25-popover/popover.module";
import { S25DropdownPaginatedModule } from "../s25-dropdown/s25.dropdown.paginated.module";

@NgModule({
    declarations: [S25TimepickerComponent],
    imports: [CommonModule, FormsModule, NgbModule, PopoverModule, S25DropdownPaginatedModule],
    providers: [S25TimepickerComponent],
    exports: [S25TimepickerComponent],
})
export class S25TimepickerModule {
    constructor() {}
}
